(function($) {
	const schibliSpotlight = {
		state: {
			post: {},
			job: {},
			credentials: {},
			'schibli-blog': {},
		},
		init() {
			const cardTemplate = wp.template('skeletone');

			$('.block-spotlight-item-wrap .spotlight-item')
				.html(cardTemplate)
				.parents('.block-spotlight-item-wrap')
				.addClass('empty');

			const postTypes = ['post', 'job', 'credentials', 'schibli-blog'];
			// eslint-disable-next-line array-callback-return
			postTypes.map(function(postType) {
				$(
					'.block-spotlight-item-wrap[data-posttype="' +
						postType +
						'"]'
				).each(function() {
					const $this = $(this);
					const key =
						$this.attr('data-companyid') +
						'-' +
						$this.attr('data-workfieldid');

					if (postType !== 'job') {
						if (!schibliSpotlight.state[postType][key]) {
							schibliSpotlight.state[postType][key] = 0;
						}

						schibliSpotlight.state[postType][key] =
							schibliSpotlight.state[postType][key] + 1;
					} else {
						if (!schibliSpotlight.state[postType][key]) {
							schibliSpotlight.state[postType][key] = {};
						}

						const jobType = $this.attr('data-jobtype');

						if (!schibliSpotlight.state[postType][key][jobType]) {
							schibliSpotlight.state[postType][key][jobType] = 0;
						}

						schibliSpotlight.state[postType][key][jobType] =
							schibliSpotlight.state[postType][key][jobType] + 1;
					}
				});
			});

			$.ajax('/wp-json/schibli-theme/v1/sscq', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				beforeSend(xhr) {
					// eslint-disable-next-line no-undef
					xhr.setRequestHeader('X-WP-Nonce', schCustom.nonce);
				},
				data: JSON.stringify({
					params: schibliSpotlight.state,
				}),
				dataType: 'json',
				success(content) {
					const queryPosts = content.query_posts;
					// console.log('queryPosts', queryPosts);
					// eslint-disable-next-line array-callback-return
					postTypes.map(function(postType) {
						if (queryPosts[postType]) {
							// eslint-disable-next-line array-callback-return
							Object.keys(queryPosts[postType]).map(function(
								key
							) {
								const ids = key.split('-'); // company_id = $key[0]; work_field_id = $key[1];

								const numberOfQueriedPosts = $(
									'.block-spotlight-item-wrap[data-posttype="' +
										postType +
										'"][data-companyid="' +
										ids[0] +
										'"][data-workfieldid="' +
										ids[1] +
										'"]'
								);
								/**
								 * Remove containers if there are more posts in the DOM than returned from the REST call
								 */
								if (
									numberOfQueriedPosts.length !==
									queryPosts[postType][key].length
								) {
									// eslint-disable-next-line array-callback-return
									numberOfQueriedPosts.filter(function(
										i,
										postBlock
									) {
										if (
											i + 1 >
											queryPosts[postType][key].length
										) {
											$(postBlock).remove();
										}
									});
								}

								/**
								 * Display returned posts
								 */
								if (postType !== 'job') {
									// eslint-disable-next-line array-callback-return
									queryPosts[postType][key].map(function(
										post,
										i
									) {
										const postBlock = jQuery(
											'.block-spotlight-item-wrap[data-posttype="' +
												postType +
												'"][data-companyid="' +
												ids[0] +
												'"][data-workfieldid="' +
												ids[1] +
												'"]'
										)[i];

										console.log(
											'post',
											post.includes(
												'news-type-fullscreen'
											)
										);
										$(postBlock)
											.find('.spotlight-item')
											.html(post)
											.addClass(function() {
												return post.includes(
													'news-type-fullscreen'
												)
													? 'is-type-fullscreen'
													: '';
											})
											.parents(
												'.block-spotlight-item-wrap'
											)
											.removeClass('empty');
									});
								} else {
									//There are 2 possible values for job types: 1 or 2

									for (let i = 1; i <= 2; i++) {
										if (queryPosts[postType][key][i]) {
											// eslint-disable-next-line array-callback-return
											queryPosts[postType][key][i].map(
												// eslint-disable-next-line array-callback-return
												function(post, index) {
													const postBlock = $(
														'.block-spotlight-item-wrap[data-posttype="' +
															postType +
															'"][data-companyid="' +
															ids[0] +
															'"][data-workfieldid="' +
															ids[1] +
															'"][data-jobtype="' +
															i +
															'"'
													)[index];
													$(postBlock)
														.find('.spotlight-item')
														.html(post)
														.parents(
															'.block-spotlight-item-wrap'
														)
														.removeClass('empty');
												}
											);
										}
									}
								}
							});
						}
					});

					$('.block-spotlight-item-wrap.empty').remove();
				},
				error(xhr, errorStatus, error) {
					console.error('error', error);
					// trigger.remove();
				},
			});

			return false;
		},
	};

	$(document).ready(function() {
		schibliSpotlight.init();
	});
})(jQuery);
